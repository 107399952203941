// Unprefix Susy
// =============


// Span
// ----
/// Un-prefixed alias for `susy-span`
/// (available by default)
///
/// @group api
/// @alias susy-span
///
/// @param {list} $span
/// @param {map} $config [()]
@function span(
  $span,
  $config: ()
) {
  @return susy-span($span, $config);
}


// Gutter
// ------
/// Un-prefixed alias for `susy-gutter`
/// (available by default)
///
/// @group api
/// @alias susy-gutter
///
/// @param {integer | list} $context [null] -
/// @param {map} $config [()]
@function gutter(
  $context: susy-get('columns'),
  $config: ()
) {
  @return susy-gutter($context, $config);
}


// Slice
// -----
/// Un-prefixed alias for `susy-slice`
/// (available by default)
///
/// @group api
/// @alias susy-slice
///
/// @param {list} $span
/// @param {map} $config [()]
@function slice(
  $span,
  $config: ()
) {
  @return susy-slice($span, $config);
}
